<template>
    <div class="founders-landing">
        <div class="animated-overlay" />
        <video-intro-modal />
        <get-started-invited v-if="$route.query.invite" :token="$route.query.invite" :invited-founder="invitedFounder" />
        <get-started-no-invited />
        <thanks-modal :invited-founder="invitedFounder" />
        <side-bar />
        <nav-bar-auth v-if="!isLoggedIn" />
        <founders-hero :is-valid-invitation="isValidInvitation" />
        <div class="container">
            <key-features />
            <community-section />
            <sample-memo />
            <more-features />
        </div>
        <apply-founders :is-valid-invitation="isValidInvitation" />
        <landing-footer />
    </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */
import { mapGetters } from "vuex";

export default {
    name: "FoundersLanding",
    components: {
        VideoIntroModal: () => import(/* webpackChunkName: "video-intro-hero" */ "./hero/video-intro-modal"),
        GetStartedInvited: () => import(/* webpackChunkName: "get-started-invited" */ "./modals/get-started-invited"),
        GetStartedNoInvited: () => import(/* webpackChunkName: "get-started-no-invited" */ "./modals/get-started-no-invited"),
        ThanksModal: () => import(/* webpackChunkName: "thanks-modal" */ "./modals/thanks-modal"),
        SideBar: () => import(/* webpackChunkName: "side-bar" */ "@c/organisms/side-bar"),
        FoundersHero: () => import(/* webpackChunkName: "founders-hero" */ "./hero/"),
        CommunitySection: () => import(/* webpackChunkName: "community-section" */ "./community"),
        KeyFeatures: () => import(/* webpackChunkName: "key-features" */ "./key-features"),
        SampleMemo: () => import(/* webpackChunkName: "sample-memo" */ "./sample-memo"),
        MoreFeatures: () => import(/* webpackChunkName: "more-features" */ "./more-features/"),
        ApplyFounders: () => import(/* webpackChunkName: "apply-founders" */ "./apply-founders"),
        LandingFooter: () => import(/* webpackChunkName: "landing-footer" */ "@v/landing/footer"),
        NavBarAuth: () => import(/* webpackChunkName: "nav-bar-auth" */ "@c/organisms/nav-bar-auth")
    },
    data() {
        return {
            invitedFounder: {}
        };
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        }),
        isInvitedFounder() {
            return Boolean(this.$route.query.invite);
        },
        isValidInvitation() {
            return this.invitedFounder.hasOwnProperty("id");
        }
    },
    created() {
        if (this.$route.query.invite) {
            this.getInvitedFounderDetail();
        }
    },
    methods: {
        getInvitedFounderDetail() {
            const invite = this.$route.query.invite;

            axiosPublic.get(`/invitations-web/${invite}`).then(({ data }) => {
                this.invitedFounder = data;
            }).catch((error) => {
                setTimeout(() => {
                    this.$swal("Invalid Invitation!", error.response.data.errors.message, "error");
                }, 2000);
                console.log(error.response.data.errors.message);
            })
        }
    }
}
</script>

<style lang="scss" >
.founders-landing {
    background-color: #181818;

    .nav-bar {
        position: relative;
    }

    .animated-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        animation-name: heroOverlay;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
    }

    .participants-container.container {
        @media(max-width: $xl) {
            max-width: 100%;
            padding-left: 0;
            padding-right: 0;
            overflow: hidden;
        }

        hr {
            height: 1px;
            background: rgb(0,175,149);
            background: linear-gradient(90deg, rgba(0,175,149,0) 0%, rgba(0,175,149,0.3981967787114846) 50%, rgba(0,175,149,0) 100%);
        }
    }
}

@keyframes heroOverlay {
    from {
        background-color: rgba(255, 255, 255, 0.25);
        height: 90vh;
        z-index: 999;
    }

    to {
        background-color: rgba(0, 0, 0, 0);
        height: 100vh;
        z-index: 0;
    }
}
</style>
